import { useEffect } from "react";
import { useNavigation } from "@remix-run/react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

export default function ProgressBar() {
  const transition = useNavigation();

  useEffect(() => {
    if (transition.state === "loading") {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [transition.state]);

  return null;
}
