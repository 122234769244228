import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  isRouteErrorResponse,
  json,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";

import "./tailwind.css";
import ErrorPage from "./components/notFoundPage";
import ProgressBar from "./components/progressbar";
import { getUser } from "./lib/auth.server";
import { prisma } from "./lib/prisma.server";
import { Toaster } from "sonner";
import {
  getDiscordActivity,
  getDiscordAvatar,
  getDiscordNames,
  initializeDiscordGateway,
} from "./lib/discord.server";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css",
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const userData = await getUser(request);

  if (!userData?.id) {
    return json({ user: null });
  }

  initializeDiscordGateway();

  const user = await prisma.user.findUnique({
    where: { id: userData.id },
    select: {
      id: true,
      profileCard: true,
      links: true,
      username: true,
      link: true,
      discordId: true,
    },
  });

  if (!user) {
    return redirect("/login");
  }

  const discordAvatarUrl = await getDiscordAvatar(user?.discordId);
  const discordUsername = await getDiscordNames(user?.discordId);
  const discordActivity = getDiscordActivity(user?.discordId);

  return json({ user, discordAvatarUrl, discordUsername, discordActivity });
}

export function ErrorBoundary() {
  const error = useRouteError();

  console.log(error);

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <ErrorPage errorMessage={error.statusText} errorStatus={error.status} />
    );
  } else {
    return (
      <ErrorPage
        errorMessage={"An unexpected error occurred."}
        errorStatus={500}
      />
    );
  }
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Toaster position="top-right" />
        <ProgressBar />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
