import { Link } from "@remix-run/react";
import Layout from "./layouts/main";

const ErrorPage = ({
  errorMessage,
  errorStatus,
}: {
  errorMessage: string;
  errorStatus: number;
}) => {
  return (
    <Layout>
      <div className="relative min-h-screen flex items-center justify-center">
        <div className="absolute inset-0 bg-background-blend clip-path-diagonal blur-sm"></div>
        <div className="absolute inset-0  clip-path-diagonal-reverse blur-sm"></div>

        <div className="relative text-center p-10 max-w-lg bg-white bg-opacity-10 backdrop-blur-lg rounded-lg shadow-2xl">
          <h1 className="text-6xl font-bold text-white mb-4">{errorStatus}</h1>
          <p className="text-lg text-[rgba(255,255,255,0.9)] mb-6">
            {errorMessage || "We encountered an unexpected error"}
          </p>

          <Link
            to="/"
            className="inline-block text-white bg-secondary/80 hover:bg-primary duration-[0.9s] ease-in-out px-6 py-3 rounded-lg shadow-lg hover:bg-opacity-90 transition-colors "
          >
            Back to Home Page
          </Link>
        </div>

        <footer className="absolute bottom-6 text-gray-400">
          <p>
            Contact By{" "}
            <a
              href="https://discord.gg/7jFEZ6tsgh"
              className="underline hover:text-white"
            >
              Joining our discord
            </a>
          </p>
        </footer>
      </div>
    </Layout>
  );
};

export default ErrorPage;
